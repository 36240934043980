import React from 'react';
import { Alert } from "react-bootstrap";

const ErrorMsg = (props) => {
    return (
        <Alert variant={props.variant}>
            <a
                onClick={(event) => {
                    event.preventDefault();
                    props.setError('');
                }}
                href="#noopt"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    color: 'black',
                    marginLeft: 5
                }}
                role="button"
            >
                X
            </a>
            <span style={{ marginLeft: 10 }}>
                {props.error}
            </span>
        </Alert>
    );
}
export default ErrorMsg;