import React from 'react';
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Button } from 'react-bootstrap'
import tuesThursRides from './images/tues-thur.jpg';
import anna from './images/anna.jpg';
import weekendRides from './images/weekend-rides.jpg';

const Home = () => {

	const navigate = useNavigate();
	const imageWidth = '300';
	const imageHeight = '230';

	return (
		<div style={{width: "100%"}}>
			<h2>Welcome!</h2>
			<p>
				Piedmont Flyers Bicycle Club is an educational, recreational,
				and social organization for bicycling enthusiasts based in
				Lewisville, NC. Most of our members live in the western triad,
				which includes Advance, Clemmons, East Bend, Greensboro,
				High Point, Lewisville, Pfafftown, and Winston-Salem.
			</p>
			<h2>Member Benefits</h2>
			<ul>
				<li>
					Meet, socialize, and ride safely with fellow cycling
					enthusiasts
				</li>
				<li>
					Mentor and encourage others to ride
				</li>
				<li>
					Liability insurance coverage for club-sponsored rides
				</li>
				<li>
					Training rides from March through September
				</li>
				<li>
					Your membership helps support the ride library
				</li>
				<li>
					Community involvement:&nbsp;
					<NavLink to="/CharityRide">Lewisville Flyer Charity Ride</NavLink>
					&nbsp;(partnership with Lewisville Civic Club and LCAP)
				</li>
			</ul>
			<div className="row">
				<div className="column">
					<img width={imageWidth} height={imageHeight} src={tuesThursRides} alt="group of riders" />
					<p style={{ width: 300, height: 125 }}>
						Everyone looks forward to Tuesday and Thursday training
						rides. There's something for everyone.
						Rides are organized by groups ranging from a steady roll
						to extremely challenging.
					</p>
					<div>
						<Button
							variant="secondary"
							onClick={() => navigate("/TueThuRides")}>
							See our training rides
						</Button>
					</div>
				</div>
				<div className="column">
					<img width={imageWidth} height={imageHeight} src={anna} alt="group of riders" />
					<p style={{ width: 300, height: 125 }}>
						Join us for our annual community fundraiser ride where we give back to the
						community.  All riding abilities are welcome. You're guaranteed to find
						someone to ride with!
					</p>
					<div>
						<Button
							variant="secondary"
							onClick={() => navigate("/CharityRide")}>
							See our fundraiser
						</Button>
					</div>
				</div>
				<div className="column">
					<img width={imageWidth} height={imageHeight} src={weekendRides} alt="group of riders" />
					<p style={{ width: 300, height: 125 }}>
						Join us on our weekend rides.
						ridewithgps.com routes are often posted
						on the local Piedmont Cycling Discord channel
						a few days before each ride.
					</p>
					<div>
						<Button onClick={() => navigate('/Library')}
							variant="secondary">
							See our ride library
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
