// Ride library page

import React, { useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import { libraryData } from './libraryData';
import { isMobile } from './utils';
import { nameFilterParams, numberFilterParams } from './agGridUtils';

const Library = () => {

  // Scroll to the top when the component is mounted.
  // Mobile firefox wants to position content
  // somewhere other than the top when initially
  // rendered. This prevents that from happening.
  useEffect(() => {
    isMobile() && window.scrollTo(0, 0);
  }, []);

  const top = useRef(null);
  const scrollToTop = () => {
    top?.current.scrollIntoView({ behavior: "smooth" });
  };

  // The data to be displayed.
  const [rowData] = useState(libraryData());

  // Column definitions
  const [colDefs] = useState([
    {
      field: "name",
      cellRenderer: params => (
        <span dangerouslySetInnerHTML={{ __html: params.data.name }}
          style={{
            whiteSpace: 'pre-wrap',
            fontSize: 'inherit',
            lineHeight: '1.2', // Adjust as needed
            display: 'inline-block',
            verticalAlign: 'middle'
          }}
        />
      ),
      wrapText: true,
      autoHeight: true,
      filter: true,
      filterParams: nameFilterParams,
      sortingOrder: ['asc', 'desc'],
      suppressMovable: true,

      // Sort by the actual name of the ride instead
      // of the link. See the ag-cell-wrapper rule in
      // index.css. It removes link underlines and
      // enables text wrapping.
      valueGetter: params => params.data.sortKey,
    },
    {
      field: "distance",
      filter: 'number',
      filterParams: numberFilterParams,
      sortingOrder: ['asc', 'desc'],
      suppressMovable: true,
      width: 110,
    },
    {
      field: "elevation",
      filter: 'number',
      filterParams: numberFilterParams,
      sortingOrder: ['asc', 'desc'],
      suppressMovable: true,
      width: 120,
    }
  ]);

  return (
    <div style={{ width: '100%' }}>
      <h2 ref={top}>Ride Library</h2>
      <div className="ag-theme-quartz">
        <AgGridReact
          domLayout='autoHeight'
          rowData={rowData}
          columnDefs={colDefs}
          overlayLoadingTemplate="<span className='ag-overlay-loading-center'>One moment please...</span>"
          overlayNoRowsTemplate="<span className='ag-overlay-loading-center'>One moment please...</span>"
        />
      </div>
      &nbsp;
      <a
        href="#noopt"
        onClick={(event) => {
          event.preventDefault();
          scrollToTop();
        }}
        role="button"
      >
        Back to top
      </a>
    </div>
  );
};

export default Library;
