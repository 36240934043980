import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from "react-router-dom";
import logo from './images/logo.png';
import { useAuth } from './auth/AuthProvider';

function Header() {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  // Check if the user is logged in
  const auth = useAuth();
  const role = auth?.user?.role;
  const isLoggedIn = auth?.user?.token || false;

  const closeMenu = () => setExpanded(false);

  return (
    <Navbar expand="lg" expanded={expanded} onToggle={setExpanded} className="navbar navbar-dark bg-dark" style={{ marginBottom: 25 }}>
      <Container>
        <Navbar.Brand as={NavLink} to="Home">
          <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
            <img src={logo}
              width="130"
              height="80" style={{ marginRight: 10 }}
              alt="piedmont flyers logo"
            />
            <div className="text-wrap">
              Piedmont Flyers Bicycle Club
            </div>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto center-menu-items">
            <NavLink onClick={closeMenu} className='menu-space margin-right menu-text-color' to="Home">Home</NavLink>
            <NavDropdown className='margin-right' title="Rides" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={closeMenu} as={NavLink} to="Library">Ride Library</NavDropdown.Item>
              <NavDropdown.Item onClick={closeMenu} as={NavLink} to="TueThuRides">Tuesday/Thursday Rides</NavDropdown.Item>
              <NavDropdown.Item onClick={closeMenu} as={NavLink} to="CharityRide">Lewisville Flyer Charity Ride</NavDropdown.Item>
            </NavDropdown>
            <NavLink onClick={closeMenu} className='menu-space margin-right menu-text-color' to="Events">Calendar</NavLink>
            <NavLink onClick={closeMenu} className='menu-space menu-text-color' to="myaccount">My account</NavLink>
            {role === 'admin' &&
              <NavDropdown className='margin-left' title="Admin" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={closeMenu} as={NavLink} to="EditDuesTiers">Dues</NavDropdown.Item>
                <NavDropdown.Item onClick={closeMenu} as={NavLink} to="MemberList">Members</NavDropdown.Item>
                <NavDropdown.Item onClick={closeMenu} as={NavLink} to="Payments">Payments</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={closeMenu} as={NavLink} to="EditTueThuRides">Edit Tue/Thu Rides</NavDropdown.Item>
                <NavDropdown.Item onClick={closeMenu} as={NavLink} to="ImportCsvFile">Import Tue/Thu Rides</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={closeMenu} as={NavLink} to="Charts">Charts</NavDropdown.Item>
              </NavDropdown>
            }
          </Nav>
          <Nav className='menu-space'>
            <Button
              variant="secondary"
              onClick={() => {
                closeMenu();
                isLoggedIn ? navigate("/Logout") : navigate("/Login");
              }}
            >
              {isLoggedIn ? "Sign out" : `Sign in | Register`}
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
