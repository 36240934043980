import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { Row, Col, Card, Container } from 'react-bootstrap';
import { isMobile } from '../utils';
import mikeClarence from '../images/mike-clarence.jpg';
import picknic from '../images/picknic.jpg';
import handwave from '../images/handwave.jpg';
import loneRider from '../images/loneRider.jpg';
import { getData } from '../getData';
import ErrorMsg from '../ErrorMsg';

export default function PayDues() {

    useEffect(() => {
        isMobile() && window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    const [error, setError] = useState('');
    const [variant, setVariant] = useState('');
    const [bronzeDues, setBronzeDues] = useState('');
    const [silverDues, setSilverDues] = useState('');
    const [goldDues, setGoldDues] = useState('');
    const imageHeight = 230;

    useEffect(() => {
        getData(setError, setVariant, {}, 'getDuesTiers')
            .then(data => {
                setBronzeDues(data[0].bronze);
                setSilverDues(data[0].silver);
                setGoldDues(data[0].gold);
            })
            .catch(err => {
                setVariant('danger');
                setError('There was a problem displaying the data.')
            });
    }, []);

    return (
        <div style={{ width: "100%" }}>
            <h1>Become a member</h1>
            {error &&
                <ErrorMsg
                    setError={setError}
                    variant={variant}
                    error={error}
                />
            }
            <p>
                Explore our membership tiers to find the one that's
                right for you. All tiers offer the same benefits.
            </p>
            <Container>
                <div style={{ width: "100%" }}>
                    <Row className="card-deck">
                        <Col md={4} className="col-lg-custom col-xl-custom">
                            <img className="mb-4" width="100%" height={imageHeight} src={mikeClarence} alt="Michael and Clarence" />
                            <Card className="dues-gold p-3">
                                <h3>Gold Membership</h3>
                                <p>
                                    Joining a cycling club isn't
                                    just about pedaling together;
                                    it's about finding a community,
                                    forging friendships, and enriching
                                    your life through memorable shared
                                    experiences and inspiring personal
                                    growth. So, what are you waiting for?
                                </p>
                                <p>
                                    <strong>
                                        ${goldDues} USD
                                    </strong>
                                </p>
                            </Card>
                            <div className="text-center mt-3 mb-4">
                                <Button
                                    variant="secondary"
                                    onClick={() => navigate('/SelectedTier', { state: ['Gold', `${goldDues}`, 'dues-gold']})}
                                >
                                    Purchase membership
                                </Button>
                            </div>
                        </Col>
                        <Col md={4} className="col-lg-custom col-xl-custom">
                            <img className="mb-4" width="100%" height={imageHeight} src={picknic} alt="group of riders" />
                            <Card className="dues-silver p-3">
                                <h3>Silver Membership</h3>
                                <p>
                                    Our club is welcoming and supportive, offering opportunities to
                                    meet new people who share your passion for adventure, fitness,
                                    and environmental responsibility. Pedal into a wider social circle
                                    and find camaraderie on two wheels!
                                </p>
                                <p>
                                    <strong>
                                        ${silverDues} USD
                                    </strong>
                                </p>
                            </Card>
                            <div className="text-center mt-3 mb-4">
                                <Button
                                    variant="secondary"
                                    onClick={() => navigate('/SelectedTier', { state: ['Silver', `${silverDues}`, 'dues-silver']})}

                                >
                                    Purchase membership
                                </Button>
                            </div>
                        </Col>
                        <Col md={4} className="col-lg-custom col-xl-custom">
                            <img className="mb-4" width="100%" height={imageHeight} src={handwave} alt="group of riders" />
                            <Card className="dues-bronze p-3">
                                <h3>Bronze Membership</h3>
                                <p>
                                    Ditch the solo spin and join the pack!
                                    Discover camaraderie, support, and
                                    shared journeys with fellow cyclists.
                                    Pedal into adventure and forge lifelong
                                    friendships – it's more than just riding,
                                    it's belonging to a community.
                                </p>
                                <p>
                                    <strong>
                                        ${bronzeDues} USD
                                    </strong>
                                </p>
                            </Card>
                            <div className="text-center mt-3 mb-4">
                                <Button
                                    variant="secondary"
                                    onClick={() => navigate('/SelectedTier', { state: ['Bronze', `${bronzeDues}`, 'dues-bronze']})}
                                >
                                    Purchase membership
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col md={6}>
                            <h1>Member Benefits</h1>
                            <p>
                                Interested in joining one of the oldest
                                cycling clubs in North Carolina?
                                We'd love to have you!
                            </p>
                            <ul>
                                <li>
                                    Meet, socialize, and ride safely with fellow cycling
                                    enthusiasts
                                </li>
                                <li>
                                    Get involved in the community.
                                    The{' '} 
                                    <NavLink to="/CharityRide">Lewisville Flyer Charity Ride</NavLink>
                                    {' '}partners with the{' '}
                                    <a href='http://www.lewisvillecivicclub.com/'>Lewisville Civic Club</a>
                                    {' '}to improve life within
                                    the community. The club also partners
                                    with the <a href='https://lcaplewisville.org/'>Lewisville Community Assistance Program</a>
                                    {' '}helping people who are in need of
                                    food and shelter.
                                </li>

                                <li>
                                    Mentor and encourage others to ride
                                </li>
                                <li>
                                    Liability insurance coverage for club-sponsored rides
                                </li>
                                <li>
                                    Training rides from March through September
                                </li>
                                <li>
                                    Your membership helps support the ride library
                                </li>
                            </ul>
                        </Col>
                        <Col md={6}>
                            <div style={{ marginTop: 25 }}>
                                <img
                                    className="mb-4"
                                    width="100%"
                                    height="100%"
                                    src={loneRider}
                                    alt="Lone female cyclist"
                                    loading="lazy"
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}