// See this article: https://designcode.io/advanced-react-hooks-handbook-paypal-checkout
// And also see this article: https://dev.to/paypaldeveloper/how-to-add-paypal-checkout-payments-to-your-react-app-53aa

import { PayPalButtons } from "@paypal/react-paypal-js";
import { useAuth } from '../auth/AuthProvider';
import { currentDate } from '../utils';

const PaypalCheckoutButton = (props) => {

    const { setMessage, priceDiv, setVariant } = props;
    const message = 'There was a problem that prevented your payment from being recorded.';
    const auth = useAuth();

    const handleApprove = async (orderId, order) => {
        try {
            const payerDetails = {
                email: order.payer?.email_address,
                firstName: order.payer.name.given_name,
                lastName: order.payer.name.surname,
                amount: order.purchase_units[0].amount.value,
                orderDate: currentDate(),
                payPalOrderStatus: order.status,
                payPalOrderId: order.id
            };

            const response = await fetch(process.env.REACT_APP_EXPRESS_SERVER + "/payment", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payerDetails),
            });
            const res = await response.json();
            if (res.success) {

                // If user is already logged in, set isDuesCurrent to true
                // so they don't have to log out and log in again to access
                // restricted content.
                if (auth.user && auth.user.email) {
                    let user = {
                        token: auth.user.token,
                        email: auth.user.email,
                        role: auth.user.role,
                        isDuesCurrent: true,
                    }
                    sessionStorage.setItem("flyers", JSON.stringify(user));
                }
                setVariant('secondary')
                setMessage(
                    <>
                        Thanks for supporting the Piedmont Flyers Bicycle Club!
                    </>
                );
                return

                // 409 indicates the payment could not be saved
                // in the PFBC database
            } else if (response.status === 409) {
                setVariant('danger')
                setMessage(res.error);
            }
        } catch (err) {
            setVariant('danger')
            setMessage(message);
            if (err instanceof TypeError && err.message.includes("Failed to fetch")) {
                console.error("Network error: Connection refused", err);
            } else {
                console.error("An unknown error occurred:", err);
            }
        }
    };

    return (
        <PayPalButtons
            style={{
                layout: 'vertical',
                color: 'silver',
                shape: "pill"
            }}
            createOrder={(data, actions) => {
                /*
                 * This is a mess but it's the only why
                 * I found to pass a dynamic price to the
                 * createOrder function. The following three
                 * lines of code retrieves the price from the
                 * <strong> element in the Checkout component
                 * so don't modify that html. If it's modified
                 * the price won't be passed to paypal.
                 * The following is the relevent html in the
                 * Checkout component.
                 * 
                 * <strong ref={priceDiv}>
                 * {product.price} USD
                 * </strong>
                 *
                 * 
                 */
                const priceStr = priceDiv.current.innerText;
                const trimmedString = priceStr.trim();
                const price = trimmedString.substring(0, trimmedString.lastIndexOf(" "));

                return actions.order.create({
                    purchase_units: [
                        {
                            description: 'Piedmont Flyers Dues',
                            amount: {
                                value: price
                            }
                        }
                    ]
                });
            }}
            onApprove={async (data, actions) => {
                const order = await actions.order.capture();
                handleApprove(data.orderID, order);
            }}
            onError={(err) => {
                setMessage('The following problem occurred while trying to process your payment: ' + JSON.stringify(err));
            }}
            onCancel={() => {
                setMessage('Your order has been cancelled.');
            }}
        />
    );
};

export default PaypalCheckoutButton;