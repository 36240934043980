import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Card } from 'react-bootstrap';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useAuth } from './auth/AuthProvider';
import ErrorMsg from './ErrorMsg';
import { formatDate } from './utils';
import RenewDuesMsg from './RenewDuesMsg';

const MyAccount = () => {

  const auth = useAuth();
  const [error, setError] = useState('');
  const [payments, setPayments] = useState([]);
  const duesCurrent = auth?.user?.isDuesCurrent;
  const memberStatus = duesCurrent ? 'green' : 'red'

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = { user: auth.user};
        const response = await fetch(process.env.REACT_APP_EXPRESS_SERVER + '/getPayments', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        const res = await response.json();
        if (response.status === 200) {
          setPayments(res);
        } else if (response.status === 400) {
          setError(res.error);
        }
      } catch (err) {
        console.error('Error during fetch:', err);
        if (err instanceof TypeError && err.message.includes('Failed to fetch')) {
          setError('Network error: Connection refused')
          console.error('Network error: Connection refused', err);
        } else {
          setError('An unknown error occurred.')
          console.error('An unknown error occurred:', err);
        }
      }
    };
    fetchData();
  }, [auth?.user?.email]);

  return (
    <div style={{ width: '100%' }}>
      <div style=
        {{
          marginTop: 15,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h2>My Account</h2>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>Member Status:</strong>&nbsp;
          <span style={{
            height: '20px',
            width: '30px',
            backgroundColor: `${memberStatus}`,
            display: 'inline-block',
          }}>
          </span>
        </div>
      </div>
      {!duesCurrent &&
        <RenewDuesMsg />
      }
      {error && <ErrorMsg
        setError={setError}
        variant='danger'
        error={error} />
      }
      <Card style={{ marginBottom: 20 }}>
        <Card.Body>
          <Card.Header style={{ backgroundColor: 'white' }}><strong>Payment History</strong></Card.Header>
          <Table>
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Amount</Th>
                <Th>Payment Status</Th>
                <Th>Payment ID</Th>
              </Tr>
            </Thead>
            <Tbody>
              {payments.map((payment, index) => (
                <Tr key={index}>
                  <Td>{formatDate(payment.orderDate)}</Td>
                  <Td>{payment.amount}</Td>
                  <Td>{payment.payPalOrderStatus}</Td>
                  <Td>{payment.payPalOrderId}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card.Body>
      </Card>
      <Card style={{marginBottom: 10}}>
        <Card.Body>
          <Card.Header style={{ backgroundColor: 'white' }}><strong>Reset Password</strong></Card.Header>
          Click{' '}
          <NavLink to="/Register">here</NavLink>
          {' '}to reset your password.
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Header style={{ backgroundColor: 'white' }}><strong>Pay Dues</strong></Card.Header>
          Click{' '}
          <NavLink to="/PayDues">here</NavLink>
          {' '}to pay your dues.
        </Card.Body>
      </Card>
    </div>
  );
};

export default MyAccount;
